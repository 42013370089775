var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticStyle:{"text-align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#6166f5"}})],1):_c('div',[(_vm.episode.publicStatus)?_c('div',{staticClass:"w-100",staticStyle:{"margin-top":"-15px"}},[_c('vue-qr',{attrs:{"text":`${_vm.episode.clinic && _vm.episode.clinic.publicNameUrl}?episodeid=${
            _vm.episode.publicNameUrl
          }`,"size":200}})],1):_vm._e(),(_vm.episode.publicStatus)?_c('div',{staticClass:"w-100 mb-2",staticStyle:{"margin-top":"-10px"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"small":"","elevation":"0","color":"grey lighten-3"},on:{"click":function($event){return _vm.openBrowserNewTab(
            `${_vm.episode.clinic && _vm.episode.clinic.publicNameUrl}/blogs/${
              _vm.episode.publicNameUrl
            }`
          )}}},[_vm._v(" "+_vm._s(_vm.$t("common.open")))])],1):_vm._e(),_c('div',{staticClass:"w-100"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"small":"","elevation":"0","color":_vm.episode.publicStatus ? 'orange' : 'green',"outlined":""},on:{"click":_vm.toggleShareStatus}},[_vm._v(_vm._s(_vm.episode.publicStatus ? _vm.$t("common.unshare") : _vm.$t("common.share"))+" "+_vm._s(_vm.$t("nav.episode")))])],1)]),_c('popup-confirm',{ref:"PopupConfirm",attrs:{"title":_vm.confirm.title,"message":_vm.confirm.message}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }